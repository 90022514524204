<template>
  <div>
    <p style="text-indent: 2em;">
      用户协议
    </p>
    <p style="text-indent: 2em;">
      发布日期：2023年3月1日
    </p>
    <p style="text-indent: 2em;">
      生效日期：2023年3月1日
    </p>
    <p style="text-indent: 2em;">
      欢迎使用 服务（以下简称“服务”）：
    </p>
    <p style="text-indent: 2em;">
      本网站由东莞市科创融资担保有限公司（以下简称“
      ”或“我们”）负责运营。本用户协议双方为 与
      注册用户（以下简称“用户”或“您”），本协议具有合同效力，适用于您在本网站的全部活动。提示：在使用服务之前，您应当认真阅读并遵守本《用户协议》（以下简称“本协议”）以及《个人信息保护政策》，请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款。免除或者限制责任的条款可能将以加粗字体显示，您应重点阅读。
    </p>
    <p style="text-indent: 2em;">
      当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您以其他
      允许的方式实际使用本服务时，即表示您已充分阅读、理解并接受本协议的全部内容，本协议即产生法律约束力。您承诺接受并遵守本协议的约定，同时承诺遵守中国法律、法规、规章及其他政府规范性文件的规定，届时您不应以未阅读本协议的内容或者未获得
      对您问询的解答等理由，主张本协议无效，或要求撤销本协议。如有违反而造成任何法律后果，您将以本人名义独立承担所有相应的法律责任。
    </p>
    <p style="text-indent: 2em;">
      您保证，您已经年满16周岁并具备完全的民事行为能力，或者您是在中国大陆地区依法设立并合法开展经营活动或其他业务的法人或其他组织。
    </p>
    <p style="text-indent: 2em;">
      一、缔约主体
    </p>
    <p style="text-indent: 2em;">
      本协议由使用 电子保函服务的用户与 共同缔结。
    </p>
    <p style="text-indent: 2em;">
      二、协议内容和效力
    </p>
    <p style="text-indent: 2em;">
      1、本协议内容包括本协议正文及所有
      已经发布的个人信息保护政策、各项政策、规则、声明、通知、警示、提示、说明（以下简称“规则”）。前述规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。
    </p>
    <p style="text-indent: 2em;">
      2、
      有权根据需要不时制订、修改本协议及相关规则，变更后的协议和规则一经公布，立即取代原协议及规则并自动生效。如您不同意相关变更，应当立即停止使用
      服务，如您继续使用
      服务或进行任何网站活动，即表示您已接受经修订的协议和规则。
    </p>
    <p style="text-indent: 2em;">
      三、服务内容
    </p>
    <p style="text-indent: 2em;">
      1、 可通过PC端网站进行访问，
      会不定期维护网站，优化操作，为用户提供更为方便的服务。如您已注册
      平台账号，则可以直接登录访问该网站。
    </p>
    <p style="text-indent: 2em;">
      2、本服务内容支持保函申请及相关服务技术功能；该功能和服务在您参与公共资源交易时起辅助作用，
      仅对该辅助功能的正常使用负有责任和义务，
      对您参与公共资源交易的结果以及您在本平台与保险公司等金融机构的交易不承担任何责任及义务；
    </p>
    <p style="text-indent: 2em;">
      3、同时，
      保留在任何时候自行决定对服务或服务任何部分及其相关功能、应用软件变更、升级、修改、转移的权利。您同意，对于上述行为，
      均不需通知，并且对您和任何第三人不承担任何责任。
    </p>
    <p style="text-indent: 2em;">
      四、注册及账号管理
    </p>
    <p style="text-indent: 2em;">
      1、您确认，在您完成注册程序或以其他
      允许的方式实际使用服务时，您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人或其他组织。若您不具备前述主体资格，请勿使用服务，否则您及您的监护人应承担因此而导致的一切后果，且
      有权注销（永久冻结）您的账户，并向您及您的监护人索偿。如您代表一家公司或其他法律主体进行注册或以其他
      允许的方式实际使用本服务，则您声明和保证，您有权使该公司或该法律主体受本协议“条款“的约束。
    </p>
    <p style="text-indent: 2em;">
      2、您注册时应提交真实、准确、完整和反映当前情况的身份及其他相关信息，并在信息发生变化后及时更新，否则
      有权立即停止服务并收回账号，因此产生的损失由您自行承担；如由于您虚假填报相应信息，导致您个人产生的损失由您自行承担，若由此使
      遭致行政处罚或其他不利后果， 有权要求您赔偿由此给 造成的损失。
    </p>
    <p style="text-indent: 2em;">
      3、您可以使用您提供或确认的手机号或者
      允许的其它方式作为账号进行注册，并承诺注册的账号名称、头像和简介等信息中不得出现违法和不良信息，不得冒充他人进行注册，不得未经许可为他人注册，不得以可能导致其他用户误认的方式注册账号，不得使用可能侵犯他人权益的用户名进行注册（包括但不限于涉嫌商标权、名誉权侵权等），否则
      有权不予注册或停止服务并收回账号，因此产生的损失由您自行承担。
    </p>
    <p style="text-indent: 2em;">
      4、在您成功注册后，
      将根据公司名称、CA（ID）、手机号和验证码（手机号账户）等信息确认您的身份。您应妥善保管您的终端，保持手机号的正常使用，并对利用该手机号的账户所进行的一切活动（包括但不限于网上点击同意或提交各类规则协议或购买服务等）负全部责任。您承诺，在该手机号账户遭到未获授权的使用，或者发生其他任何安全问题时，将立即通知
      ，且您同意并确认，
      不对上述情形产生的任何直接或间接的遗失或损害承担责任。除非有法律规定或司法裁定，且征得
      的同意，否则，您的账户不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）。
    </p>
    <p style="text-indent: 2em;">
      5、您使用 服务即表示您同意
      将您的个人身份信息或企业信息传输至相关的金融机构。
    </p>
    <p style="text-indent: 2em;">
      五、服务使用规范
    </p>
    <p style="text-indent: 2em;">
      1、用户充分了解并同意，
      仅为用户提供服务平台，您应自行对利用服务从事的所有行为及结果承担责任。相应地，您应了解，使用
      服务可能发生来自他人非法或不当行为（或信息）的风险，您应自行判断及行动，并自行承担相应的风险。、
    </p>
    <p style="text-indent: 2em;">
      2、您应自行承担因使用服务所产生的费用，包括但不限于上网流量费、通信服务费等。
    </p>
    <p style="text-indent: 2em;">
      3、您承诺不会利用本服务进行任何违法或不当的活动，包括但不限于下列行为：
    </p>
    <p style="text-indent: 2em;">
      3.1上载含有下列内容之一的信息：
    </p>
    <p style="text-indent: 2em;">
      （a）反对宪法所确定的基本原则的；
    </p>
    <p style="text-indent: 2em;">
      （b）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
    </p>
    <p style="text-indent: 2em;">
      （c）损害国家荣誉和利益的；
    </p>
    <p style="text-indent: 2em;">
      （d）煽动民族仇恨、民族歧视、破坏民族团结的；
    </p>
    <p style="text-indent: 2em;">
      （e）破坏国家宗教政策，宣扬邪教和封建迷信的；
    </p>
    <p style="text-indent: 2em;">
      （f）散布谣言，扰乱社会秩序，破坏社会稳定的；
    </p>
    <p style="text-indent: 2em;">
      （g）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
    </p>
    <p style="text-indent: 2em;">
      （h）侮辱或者诽谤他人，侵害他人合法权利的；
    </p>
    <p style="text-indent: 2em;">
      （i）含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；
    </p>
    <p style="text-indent: 2em;">
      （j）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；
    </p>
    <p style="text-indent: 2em;">
      3.2 冒充任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关；
    </p>
    <p style="text-indent: 2em;">
      3.3以其他方式操控识别资料，使人误认为该内容为 或其关联公司所传送；
    </p>
    <p style="text-indent: 2em;">
      3.4违反遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的行为；
    </p>
    <p style="text-indent: 2em;">
      3.5从事任何违反中国法律、法规、规章、政策及规范性文件的行为。
    </p>
    <p style="text-indent: 2em;">
      4、您承诺不从事以下行为：
    </p>
    <p style="text-indent: 2em;">
      4.1
      将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送；
    </p>
    <p style="text-indent: 2em;">
      4.2
      干扰或破坏本服务或与本服务相连线之服务器和网络，或违反任何关于本服务连线网络之规定、程序、政策或规范；
    </p>
    <p style="text-indent: 2em;">
      4.3
      通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
    </p>
    <p style="text-indent: 2em;">
      4.4 通过非
      开发、授权的第三方软件、插件、外挂、系统，登录或使用软件及服务，或制作、发布、传播上述工具；
    </p>
    <p style="text-indent: 2em;">
      4.5 自行、授权他人或利用第三方软件对本软件及其组件、模块、数据等进行干扰。
    </p>
    <p style="text-indent: 2em;">
      5、您承诺，使用 服务时您将严格遵守本协议(包括本协议第一条所述规则)。
    </p>
    <p style="text-indent: 2em;">
      6、您承诺不以任何形式使用本服务侵犯 的商业利益，或从事任何可能对
      造成损害或不利于 的行为。
    </p>
    <p style="text-indent: 2em;">
      7、您充分了解并同意，您通过
      所购买的每一份CA证书，其所代表的单位或个人均有明确的身份归属（具体归属以CA证书所载信息为准）；其用途仅限于代表本单位或本人（具体归属以CA证书所载信息为准）的文档签名和文档加密；任何超出此用途以外的使用均为不当使用，例如您不得将代表甲单位的CA证书用于对本应使用乙单位CA证书进行加密或签名的文件；任何不当使用造成的后果均由您自行负担。
    </p>
    <p style="text-indent: 2em;">
      8、如果 发现或收到他人举报您有违反本协议约定的，
      有权不经通知随时对相关内容进行删除，并采取包括但不限于收回账号，限制、暂停、终止您使用部分或全部本服务，追究法律责任等措施。
    </p>
    <p style="text-indent: 2em;">
      9、您应当妥善保管您的账号密码、数字证书和私钥及PIN码，不得泄漏或交付他人。如因故意、过失导致数字证书私钥或PIN码被他人盗用、冒用、伪造或者篡改时，您应承担由此产生的一切风险及对应法律责任。
    </p>
    <p style="text-indent: 2em;">
      10、您独立享有数字证书的使用权，但不得用于涉及违反国家法律、法规或危害国家安全的用途。因使用数字证书而产生的义务、责任由您自行承担。
    </p>
    <p style="text-indent: 2em;">
      11、基于计算机端、手机端以及使用其他电子设备的用户使用习惯，我们可能在您使用具体产品时设置不同的账户登录模式及采取不同的措施识别您的身份。本公司通过以下种方式接受来自您的指令：您在本网站或其他可使用本服务的网站或软件上通过以您的手机号码及密码或数字证书登录
      账户并依照服务预设流程所修改或确认的指令。
    </p>
    <p style="text-indent: 2em;">
      12、因您的证明材料错误、图像模糊、上传文件错误等非
      方面的原因，致您不能及时享受 的服务并开展招投标活动的，
      不对您承担任何责任。
    </p>
    <p style="text-indent: 2em;">
      六、特别授权
    </p>
    <p style="text-indent: 2em;">
      一旦您违反本协议，或与 签订的其他协议的约定， 有权以任何方式通知
      关联公司，要求其对您的权益采取限制措施，包括但不限于要求
      关联公司中止、终止对您提供部分或全部服务，且在其经营或实际控制的任何网站公示您的违约情况。
    </p>
    <p style="text-indent: 2em;">
      七、第三方应用
    </p>
    <p style="text-indent: 2em;">
      1、 允许第三方应用接入服务平台，您了解并同意，
      仅作为平台提供者，相关应用由该第三方独立提供，
      不对您对该应用的使用承担任何责任。
    </p>
    <p style="text-indent: 2em;">
      2、您了解并同意，如
      对服务或其部分做出调整、中止或终止而对第三方应用服务产生影响，
      不承担任何责任。
    </p>
    <p style="text-indent: 2em;">
      八、服务中止或终止
    </p>
    <p style="text-indent: 2em;">
      1、您同意，鉴于互联网服务的特殊性，
      有权随时中止、终止或致使中止终止服务或其任何部分；对于免费服务之中止或终止，
      无需向您发出通知。
    </p>
    <p style="text-indent: 2em;">
      2、您了解并同意，
      可能定期或不定期地对提供网络服务的平台设备、设施和软硬件进行维护或检修，如因此类情况而造成收费服务在合理时间内中止，
      无需承担责任，但应尽可能事先进行通告。
    </p>
    <p style="text-indent: 2em;">
      3、如存在下列违约情形之一，
      可立即对用户中止或终止服务，并要求用户赔偿损失：
    </p>
    <p style="text-indent: 2em;">
      3.1 用户违反第四条之注册义务；
    </p>
    <p style="text-indent: 2em;">
      3.2 用户使用收费网络服务时未按规定支付相应服务费；
    </p>
    <p style="text-indent: 2em;">
      3.3 用户违反第五条服务使用规范之规定。
    </p>
    <p style="text-indent: 2em;">
      九、隐私政策
    </p>
    <p style="text-indent: 2em;">
      1、您在
      服务注册的账户具有密码保护功能，以确保您基本信息资料的安全，请您妥善保管账户及密码信息。
    </p>
    <p style="text-indent: 2em;">
      2、
      努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您存储在
      中的信息和通信内容不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署相关协议，监控他们的操作情况等措施。
      会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，
      将在任何时候尽力做到使您的信息不被泄漏、毁损或丢失，但同时也请您注意在信息网络上不存在绝对完善的安全措施，请妥善保管好相关信息。
    </p>
    <p style="text-indent: 2em;">
      3、
      有权根据实际情况自行决定您在本软件及服务中数据的最长储存期限、服务器中数据的最大存储空间等，您应当保管好终端、账号及密码，并妥善保管相关信息和内容，
      对您自身原因导致的数据丢失或被盗以及在本软件及服务中相关数据的删除或储存失败不承担责任。
    </p>
    <p style="text-indent: 2em;">
      4、其他隐私条款见 《个人信息保护政策》。
    </p>
    <p style="text-indent: 2em;">
      十、知识产权
    </p>
    <p style="text-indent: 2em;">
      1、您了解及同意，除非
      另行声明，本协议项下服务包含的所有产品、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密等）及相关权利均归
      公司所有。
    </p>
    <p style="text-indent: 2em;">
      2、您应保证，除非取得
      书面授权，对于上述权利您不得（并不得允许任何第三人）实施包括但不限于出租、出借、出售、散布、复制、修改、转载、汇编、发表、出版、还原工程、反向汇编、反向编译，或以其它方式发现原始码等的行为。
    </p>
    <p style="text-indent: 2em;">
      3、 服务涉及的Logo、“ ”等文字、图形及其组成，以及
      其他标识、徵记、产品和服务名称均为 公司的商标，用户未经
      书面授权不得以任何方式展示、使用或作其他处理，也不得向他人表明您有权展示、使用、或作其他处理。
    </p>
    <p style="text-indent: 2em;">
      4、您理解并同意授权
      在宣传和推广中使用您的名称、商标、标识，但仅限于表明您属于我们的客户或合作伙伴。
    </p>
    <p style="text-indent: 2em;">
      十一、有限责任
    </p>
    <p style="text-indent: 2em;">
      1、服务将按”现状”和按”可得到”的状态提供。
      在此明确声明对服务不作任何明示或暗示的保证，包括但不限于对服务的可适用性，没有错误或疏漏，持续性，准确性，可靠性，适用于某一特定用途之类的保证，声明或承诺。
    </p>
    <p style="text-indent: 2em;">
      2、
      对服务所涉的技术和信息的有效性，准确性，正确性，可靠性，质量，稳定，完整和及时性均不作承诺和保证。
    </p>
    <p style="text-indent: 2em;">
      3、不论在何种情况下，
      均不对由于Internet连接故障，电脑，通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。
    </p>
    <p style="text-indent: 2em;">
      4、不论是否可以预见，不论是源于何种形式的行为，
      不对由以下原因造成的任何特别的，直接的，间接的，惩罚性的，突发性的或有因果关系的损害或其他任何损害（包括但不限于利润或利息的损失，营业中止，资料灭失）承担责任。
    </p>
    <p style="text-indent: 2em;">
      4.1 使用或不能使用服务；
    </p>
    <p style="text-indent: 2em;">
      4.2
      通过服务购买或获取任何产品，样品，数据，信息或进行交易等，或其他可替代上述行为的行为而产生的费用；
    </p>
    <p style="text-indent: 2em;">
      4.3 未经授权的存取或修改数据或数据的传输；
    </p>
    <p style="text-indent: 2em;">
      4.4 第三方通过服务所作的陈述或行为；
    </p>
    <p style="text-indent: 2em;">
      4.5 其它与服务相关事件，包括疏忽等，所造成的损害。
    </p>
    <p style="text-indent: 2em;">
      5、您充分了解并同意，鉴于互联网体制及环境的特殊性，您在服务中分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途；您在此已充分意识此类风险的存在，并确认此等风险应完全由您自行承担，
      对此不承担任何责任。
    </p>
    <p style="text-indent: 2em;">
      6、您了解并同意，在使用服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）及匿名或冒名的信息的风险，基于服务使用规范所述，该等风险应由您自行承担，
      对此不承担任何责任。
    </p>
    <p style="text-indent: 2em;">
      十二、违约责任及赔偿
    </p>
    <p style="text-indent: 2em;">
      1、如果 发现或收到他人举报您有违反本协议约定的，
      有权不经通知随时对相关内容进行删除、屏蔽，并采取包括但不限于限制、暂停、终止您使用
      账号及服务，限制、暂停、终止您使用部分或全部本服务（包括但不限于解除与团体或成员的所属关系，删除通信录、限制增加成员、限制发送消息或添加好友等），追究法律责任等措施。
    </p>
    <p style="text-indent: 2em;">
      2、您同意，由于您通过服务上载之信息、使用本服务其他功能、违反本协议、或您侵害他人任何权利因而衍生或导致任何第三人向
      及其关联公司提出任何索赔或请求，或
      及其关联公司因此而发生任何损失，您同意将足额进行赔偿（包括但不限于合理律师费）。
    </p>
    <p style="text-indent: 2em;">
      十三、有效通知
    </p>
    <p style="text-indent: 2em;">
      1、
      向您发出的任何通知，可采用电子邮件、页面公开区域公告、个人网络区域提示、手机短信或常规信件等方式，且该等通知应自发送之日视为已向用户送达或生效。
    </p>
    <p style="text-indent: 2em;">
      2、您同意，您向 发出的任何通知应发至
      对外正式公布或以本协议末尾所述方式告知用户的电子邮件、通信地址、传真号码等联系信息，或使用其他
      认可的其他通知方式进行送达。
    </p>
    <p style="text-indent: 2em;">
      十四、争议解决及其他
    </p>
    <p style="text-indent: 2em;">
      1、本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理，并以广州市越秀区人民法院为第一审管辖法院。
    </p>
    <p style="text-indent: 2em;">
      2、如本协议的任何条款被视作无效或无法执行，不影响其余部份条款的法律效力。
    </p>
    <p style="text-indent: 2em;">
      十五、如何联系我们
    </p>
    <p style="text-indent: 2em;">
      如果您对
      服务有任何疑问、意见或建议，可以通过以下方式与我们联系，我们邮寄联系方式如下：
    </p>
    <p style="text-indent: 2em;">
      收件人：东莞市科创融资担保有限公司
    </p>
    <p style="text-indent: 2em;">
      地址：东莞市松山湖高新技术开发区新城大道5号道弘龙怡智谷A座8 楼
    </p>
    <p style="text-indent: 2em;">
      电话：0769-22863128
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
