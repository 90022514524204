<template>
  <div class="form" ref="form">
    <a-form
      :form="form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 12 }"
      @submit="handleSubmit"
    >
      <a-form-item label="用户名" has-feedback>
        <a-input
          v-decorator="[
            'username',
            { rules: [{ validator: compareToFirstUserName }] },
          ]"
          placeholder="请输入用户名"
          style="width: 280px"
        />
      </a-form-item>
      <a-form-item label="手机号码" has-feedback>
        <a-input
          v-decorator="[
            'phoneNum',
            { rules: [{ validator: compareToFirstPhone }] },
          ]"
          placeholder="请输入手机号码"
          style="width: 280px"
        />
      </a-form-item>
      <a-form-item label="验证码" class="code">
        <a-input
          v-decorator="['code', { rules: [{ validator: compareToFirstCode }] }]"
          placeholder="请输入验证码"
          style="width: 280px"
        />
        <img :src="codeImg" @click="getImgcode" />
      </a-form-item>
      <a-form-item label="短信验证码" has-feedback class="code">
        <a-input
          v-decorator="[
            'phCode',
            { rules: [{ validator: compareToFirstPhcode }] },
          ]"
          placeholder="请输入短信验证码"
          style="width: 280px"
        />
        <a v-if="isTime" @click="getPhcode"> 获取验证码 </a>
        <span v-else> {{ time }}s重新获取 </span>
      </a-form-item>
      <a-form-item label="登录密码" has-feedback>
        <a-tooltip placement="right" title="密码须8-16位且包含数字、大小写字母">
          <a-input-password
            v-decorator="[
              'password',
              { rules: [{ validator: compareToFirstPassword }] },
            ]"
            placeholder="请输入登录密码"
            style="width: 280px"
          />
        </a-tooltip>
      </a-form-item>
      <a-form-item label="确认密码" has-feedback>
        <a-tooltip placement="right" title="密码须8-16位且包含数字、大小写字母">
          <a-input-password
            v-decorator="[
              'conPassword',
              { rules: [{ validator: compareToFirstConPassword }] },
            ]"
            placeholder="请再次输入登录密码"
            style="width: 280px"
          />
        </a-tooltip>
      </a-form-item>
      <div class="checkbox">
        <a-checkbox class="check" @change="handleKenwChange" />
        <div>
          我已阅读并同意<a @click="userModal">《用户协议》</a>和<a
            @click="personModal"
            >《个人隐私协议》</a
          >
        </div>
      </div>
      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <a-button type="primary" html-type="submit"> 立即注册 </a-button>
      </a-form-item>
    </a-form>
    <div>
      <a-modal
        title="《用户协议》"
        :visible="userVisible"
        @cancel="userCancel"
        :get-container="() => $refs.form"
      >
        <UserAgreement />
        <template slot="footer">
          <a-button @click="userOk" type="primary"> 确认 </a-button>
        </template>
      </a-modal>
    </div>
    <div>
      <a-modal
        title="《个人隐私协议》"
        :visible="personVisible"
        @cancel="personCancel"
        :get-container="() => $refs.form"
      >
        <PersonalPrivacy />
        <template slot="footer">
          <a-button @click="personOk" type="primary"> 确认 </a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>
<script>
// import { Checkbox } from 'ant-design-vue';
import { graphicsCode, smsCode } from "@/api/code.js";
import { register } from "@/api/login/register.js";
import UserAgreement from "./UserAgreement.vue";
import PersonalPrivacy from "./personalPrivacy.vue";
export default {
  name: "Form",
  components: {
    UserAgreement,
    PersonalPrivacy,
  },
  data() {
    return {
      userVisible: false,
      personVisible: false,

      form: this.$form.createForm(this, { name: "loginForm" }),
      regChecked: false,
      codeImg: "",
      isCode: "",
      isTime: true,
      timer: "",
      time: 10,
    };
  },
  mounted() {
    this.getImgcode();
    this.form.setFieldsValue({
      username: "",
      phoneNum: "",
      code: "",
      phCode: "",
      password: "",
      conPassword: "",
    });
  },
  methods: {
    userModal() {
      this.userVisible = true;
    },
    userOk() {
      this.userVisible = false;
    },
    userCancel() {
      this.userVisible = false;
    },

    personModal() {
      this.personVisible = true;
    },
    personOk() {
      this.personVisible = false;
    },
    personCancel() {
      this.personVisible = false;
    },

    /* 勾选 */
    handleKenwChange(e) {
      this.regChecked = e.target.checked;
    },
    /* 立即注册 */
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.regChecked) {
            this.$message.warning("请阅读并同意《用户协议》和《个人隐私协议》");
            return false;
          }
          const params = {
            username: values.username,
            phoneCode: values.phCode,
            phoneNum: values.phoneNum,
            pwd: values.password,
            confirmPwd: values.conPassword,
            partnerSource: localStorage.getItem("partnerSource") || "",
          };
          register({ ...params }).then((res) => {
            if (res.data.status === "SUCCESS") {
              this.$parent.visSuccess();
            } else {
              this.$message.warning(res.data.errorMsg);
            }
          });
        }
      });
    },
    /* 图片验证码 */
    getImgcode() {
      graphicsCode({ source: "ACC_REG_CODE" }).then((res) => {
        this.codeImg = window.URL.createObjectURL(res.data);
      });
    },
    /* 获取验证码 */
    getPhcode() {
      this.timer = "";
      if (
        this.form.instances.phoneNum.stateValue !== "" &&
        this.form.instances.code.stateValue !== ""
      ) {
        smsCode({
          source: "ACC_REG_CODE",
          checkCode: this.form.instances.code.stateValue,
          phoneNum: this.form.instances.phoneNum.stateValue,
        }).then((res) => {
          this.isCode = res.data;
          if (res.data.status === "FAIL") {
            this.$message.warning(this.isCode.errorMsg);
          } else {
            this.isTime = false;
            this.timer = setInterval(this.countDown, 1000);
          }
        });
      } else {
        this.$message.warning("手机号码和图形验证码不为空");
      }
    },
    countDown() {
      if (this.time > 0) {
        this.time--;
      } else {
        this.time = 10;
        this.isTime = true;
        clearInterval(this.timer);
      }
    },
    // 自定义校验
    compareToFirstUserName(rule, value, callback) {
      if (value === "") {
        callback("请输入用户名");
      } else {
        callback();
      }
    },
    compareToFirstPhone(rule, value, callback) {
      const pattern = /^1[0-9]{10}$/;
      if (pattern.test(value)) {
        callback();
      } else {
        callback("请输入正确的手机号");
      }
    },
    compareToFirstCode(rule, value, callback) {
      if (value === "") {
        callback("请输入图形验证码");
      } else {
        callback();
      }
    },
    compareToFirstPhcode(rule, value, callback) {
      if (value === "") {
        callback("请输入短信验证码");
      } else {
        callback();
      }
    },
    compareToFirstPassword(rule, value, callback) {
      const pattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[a-z])[a-zA-Z0-9]{8,16}$/;
      if (pattern.test(value)) {
        callback();
      } else {
        callback("密码须8-16位且包含数字、大小写字母");
      }
    },
    compareToFirstConPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback("密码输入不一致");
      } else {
        callback();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  display: flex;
  justify-content: center;

  /deep/ .ant-form {
    button {
      width: 280px;
      height: 44px;
      background: #007ce2;
      box-shadow: 0px 5px 8px 0px rgba(136, 189, 232, 0.3);
      border-radius: 3px;
      margin-left: 10px;
    }
  }

  /deep/ .ant-form-item-label {
    width: 100px;
  }

  /deep/ .ant-form-item label {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }

  /deep/ .ant-input {
    height: 40px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 3px;
  }

  .code {
    position: relative;

    img {
      width: 84px;
      height: 27px;
      position: absolute;
      top: -4px;
      right: 0;
      background: #fff;
      cursor: pointer;
    }

    a {
      position: absolute;
      top: -10px;
      right: 10px;
    }

    span {
      position: absolute;
      top: -10px;
      right: 10px;
      color: #aaa;
    }

    /deep/ .ant-input {
      padding-right: 110px;
    }

    /deep/ .ant-form-item-children-icon {
      right: 80px;
    }
  }

  .checkbox {
    display: flex;
    margin: 0 0 20px 100px;

    .check {
      margin-right: 10px;
    }
  }

  /deep/ .ant-modal-body {
    height: 500px;
    overflow: auto;
  }
}
</style>
