var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"form",staticClass:"form"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"用户名","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'username',
          { rules: [{ validator: _vm.compareToFirstUserName }] },
        ]),expression:"[\n          'username',\n          { rules: [{ validator: compareToFirstUserName }] },\n        ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入用户名"}})],1),_c('a-form-item',{attrs:{"label":"手机号码","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'phoneNum',
          { rules: [{ validator: _vm.compareToFirstPhone }] },
        ]),expression:"[\n          'phoneNum',\n          { rules: [{ validator: compareToFirstPhone }] },\n        ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入手机号码"}})],1),_c('a-form-item',{staticClass:"code",attrs:{"label":"验证码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code', { rules: [{ validator: _vm.compareToFirstCode }] }]),expression:"['code', { rules: [{ validator: compareToFirstCode }] }]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入验证码"}}),_c('img',{attrs:{"src":_vm.codeImg},on:{"click":_vm.getImgcode}})],1),_c('a-form-item',{staticClass:"code",attrs:{"label":"短信验证码","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'phCode',
          { rules: [{ validator: _vm.compareToFirstPhcode }] },
        ]),expression:"[\n          'phCode',\n          { rules: [{ validator: compareToFirstPhcode }] },\n        ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入短信验证码"}}),(_vm.isTime)?_c('a',{on:{"click":_vm.getPhcode}},[_vm._v(" 获取验证码 ")]):_c('span',[_vm._v(" "+_vm._s(_vm.time)+"s重新获取 ")])],1),_c('a-form-item',{attrs:{"label":"登录密码","has-feedback":""}},[_c('a-tooltip',{attrs:{"placement":"right","title":"密码须8-16位且包含数字、大小写字母"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            { rules: [{ validator: _vm.compareToFirstPassword }] },
          ]),expression:"[\n            'password',\n            { rules: [{ validator: compareToFirstPassword }] },\n          ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入登录密码"}})],1)],1),_c('a-form-item',{attrs:{"label":"确认密码","has-feedback":""}},[_c('a-tooltip',{attrs:{"placement":"right","title":"密码须8-16位且包含数字、大小写字母"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'conPassword',
            { rules: [{ validator: _vm.compareToFirstConPassword }] },
          ]),expression:"[\n            'conPassword',\n            { rules: [{ validator: compareToFirstConPassword }] },\n          ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请再次输入登录密码"}})],1)],1),_c('div',{staticClass:"checkbox"},[_c('a-checkbox',{staticClass:"check",on:{"change":_vm.handleKenwChange}}),_c('div',[_vm._v(" 我已阅读并同意"),_c('a',{on:{"click":_vm.userModal}},[_vm._v("《用户协议》")]),_vm._v("和"),_c('a',{on:{"click":_vm.personModal}},[_vm._v("《个人隐私协议》")])])],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 立即注册 ")])],1)],1),_c('div',[_c('a-modal',{attrs:{"title":"《用户协议》","visible":_vm.userVisible,"get-container":() => _vm.$refs.form},on:{"cancel":_vm.userCancel}},[_c('UserAgreement'),_c('template',{slot:"footer"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.userOk}},[_vm._v(" 确认 ")])],1)],2)],1),_c('div',[_c('a-modal',{attrs:{"title":"《个人隐私协议》","visible":_vm.personVisible,"get-container":() => _vm.$refs.form},on:{"cancel":_vm.personCancel}},[_c('PersonalPrivacy'),_c('template',{slot:"footer"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.personOk}},[_vm._v(" 确认 ")])],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }