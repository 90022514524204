<template>
  <div>
    <p style="text-indent: 2em;">
      个人信息保护政策
    </p>
    <p style="text-indent: 2em;">
      发布日期：2023年3月1日
    </p>
    <p style="text-indent: 2em;">
      生效日期：2023年3月1日
    </p>
    <p style="text-indent: 2em;">
      感谢您使用我们的产品与服务：
    </p>
    <p style="text-indent: 2em;">
      东莞市科创融资担保有限公司（以下称“我们”或“”）非常重视用户（以下简称“您”）的隐私和个人信息保护。您在使用我们的产品与服务时，我们可能会收集和使用您的相关个人信息。我们通过本《个人信息保护政策》（下称本政策）向您说明
      在提供产品与服务时如何收集、使用、加工、保存、传输、提供、公开您的个人信息、您享用的个人信息用户权利以及
      如何保障您的个人信息安全。
    </p>
    <p style="text-indent: 2em;">
      您的信任对我们非常重要，我们深知个人信息对您的重要性，希望您在提供您的个人信息时仔细阅读并确认您已经知悉并理解本政策，一旦您开始使用
      产品或服务，即表示您已充分理解并同意本政策。
    </p>
    <p style="text-indent: 2em;">
      我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。有鉴于此，我们制定本政策并提醒您：
    </p>
    <p style="text-indent: 2em;">
      需要特别说明的是，本隐私政策不适用于其他第三方通过网页或客户端等其他形式直接向您提供的服务（统称“第三方服务”），例如您可能通过
      使用支付宝的支付功能进行转账付款，您向支付宝的运营商提供的信息不适用于本政策，您在选择使用第三服务前应充分了解第三方服务的产品功能及隐私保护政策，再选择是否开通功能。
    </p>
    <p style="text-indent: 2em;">
      如您对本政策内容有任何疑问、意见或建议，可以通过【5*8小时客服热线0769-22863128）】方式与我们联系。
    </p>
    <p style="text-indent: 2em;">
      本政策将帮助您了解以下内容：
    </p>
    <p style="text-indent: 2em;">
      第一部分法律声明
    </p>
    <p style="text-indent: 2em;">
      一、权利归属
    </p>
    <p style="text-indent: 2em;">
      的Logo、“”等文字、图形及其组合，以及其他标识、徽记、服务的名称等为及关联公司的注册商标。未经书面授权，任何人不得以任何方式展示、使用或做其他处理（包括但不限于复制、传播、展示、镜像、上传、下载），也不得向他人表明您有权展示、使用或做其他处理。
    </p>
    <p style="text-indent: 2em;">
      公司所有的产品、服务、技术与所有应用程序或其组件/功能/名称（以下或简称“技术服务”）的知识产权均归属于
      所有或归其权利人所有。未经
      许可，任何人不得擅自使用如上内容（包括但不限于通过程序或设备监视、复制、转播、展示、镜像、上传、下载网站内的任何内容）。被授权浏览、复制、打印和传播属于
      产品和服务内信息内容的，该等内容都不得用于商业目的且所有信息内容及其任何部分的使用都必须包括此权利声明。
    </p>
    <p style="text-indent: 2em;">
      二、责任限制
    </p>
    <p style="text-indent: 2em;">
      用户在
      移动客户端以及开放平台上自行上传、提供、发布相关信息，包括但不限于用户名称、公司名称、联系人及联络信息，相关图片等，该等信息均由用户自行提供，
      用户须对其提供的信息依法承担全部责任。
    </p>
    <p style="text-indent: 2em;">
      在此提示，您在使用
      产品及服务期间应当遵守中华人民共和国的法律，不得危害网络安全，不得利用
      产品及服务从事侵犯他人名誉、隐私、知识产权和其他合法权益的活动。尽管有前述提示，
      不对您使用 产品及服务的用途和目的承担任何责任。
    </p>
    <p style="text-indent: 2em;">
      第二部分隐私权政策
    </p>
    <p style="text-indent: 2em;">
      本隐私政策部分将帮助您了解以下内容：
    </p>
    <p style="text-indent: 2em;">
      一、我们如何收集和使用您的个人信息
    </p>
    <p style="text-indent: 2em;">
      二、我们如何使用Cookie和同类技术
    </p>
    <p style="text-indent: 2em;">
      三、我们如何共享、转让、公开披露您的个人信息
    </p>
    <p style="text-indent: 2em;">
      四、我们如何保护、保存您的个人信息
    </p>
    <p style="text-indent: 2em;">
      五、您如何管理个人信息
    </p>
    <p style="text-indent: 2em;">
      六、未成年人信息的保护
    </p>
    <p style="text-indent: 2em;">
      七、本隐私政策如何更新
    </p>
    <p style="text-indent: 2em;">
      八、如何联系我们
    </p>
    <p style="text-indent: 2em;">
      一、我们如何收集和使用您的个人信息
    </p>
    <p style="text-indent: 2em;">
      （一）我们如何收集您的信息
    </p>
    <p style="text-indent: 2em;">
      为了向您及提供服务，维护
      服务的正常运行，改进及优化我们的服务并保障您的账号安全，我们会出于本政策下述目的及方式收集您在注册、使用
      服务时主动提供、授权提供或以及基于您使用 服务时产生的信息：
    </p>
    <p style="text-indent: 2em;">
      1、注册 用户信息
    </p>
    <p style="text-indent: 2em;">
      为注册成为
      用户，以便我们为您提供基础的服务，诸如保函申请提交等服务，您需要提供您的手机号码及短信验证码以注册并创建
      账号，上述信息为您注册
      账号所必需，若您不提供这类信息，您将无法继续使用我们的服务。
    </p>
    <p style="text-indent: 2em;">
      注册成为
      用户后，为了享受部分服务功能，您需要进行实名认证，您须进一步提供姓名、身份证号、所在企业信息等基本信息。如果您仅需使用浏览、搜索官网展示的
      产品、功能及服务介绍，您不需要注册成为 用户并提供上述信息。
    </p>
    <p style="text-indent: 2em;">
      2、使用 服务过程中收集信息
    </p>
    <p style="text-indent: 2em;">
      当您在使用
      服务过程中，为向您提供更契合您需求的公共资源交易中心关联服务、识别账号异常状态，维护
      服务的正常运行，改进及优化您对 服务的体验并保障您的账号安全，包括您使用
      服务以及使用方式的信息，并将这些信息进行关联：
    </p>
    <p style="text-indent: 2em;">
      1)设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息。
    </p>
    <p style="text-indent: 2em;">
      2)日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间、您访问的页面记录，等等。
    </p>
    <p style="text-indent: 2em;">
      请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将有可能被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
    </p>
    <p style="text-indent: 2em;">
      我们收集及使用上述信息的目的是为了更好地提供产品和服务，如有更新我们会通过包括但不限于通过网站公告、电子邮件、电话或短信等方式通知您。
    </p>
    <p style="text-indent: 2em;">
      3、您向我们提供的信息
    </p>
    <p style="text-indent: 2em;">
      在服务使用过程中，您可以对
      产品及服务的体验问题反馈，帮助我们更好地了解您使用我们产品或服务的体验和需求，改善我们产品或服务
    </p>
    ,为此我们会记录您的联系信息、反馈的问题或建议，以便我们进一步联系您反馈您我们的处理意见。
    <p style="text-indent: 2em;">
      4、我们在您使用个性推荐服务过程中收集的信息
    </p>
    <p style="text-indent: 2em;">
      为向您提供更好、更个性化的服务，了解产品适配性，识别账号异常状态，我们会收集您使用服务的相关信息，这些信息包括：设备信息、日志、服务使用信息。
    </p>
    <p style="text-indent: 2em;">
      5、第三方向我们提供的信息
    </p>
    <p style="text-indent: 2em;">
      我们将对其他用户进行与您相关的操作时收集关于您的信息，亦可能从
      关联公司、合作第三方或通过其他合法途径获取的您的个人数据信息。例如，从公共资源交易中心系统，通过您个人的登录绑定操作，获取包括但不限于您的个人信息，包括、姓名、身份证号、所在企业信息、信息推送系统等信息。
    </p>
    <p style="text-indent: 2em;">
      6、为您提供安全保障收集信息
    </p>
    <p style="text-indent: 2em;">
      为预防、发现、调查欺诈、侵权、危害安全、非法或违反与我们或与我们关联公司的协议、政策或规则的行为，我们可能收集或整合您的用户个人信息、服务使用信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息。
    </p>
    <p style="text-indent: 2em;">
      7、其他目的收集信息
    </p>
    <p style="text-indent: 2em;">
      8、我们将基于本政策未载明的其他特定目的收集您的信息时，会事先告知并征求您的同意。我们收集及使用上述信息的目的是为了更好地经营
      产品和服务（包括但不限于向您提供个性化的服务），并且会通过包括但不限于通过网站通知、电子邮件、电话或短信息等方式通知您。
    </p>
    <p style="text-indent: 2em;">
      9、如我们停止运营
      产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以提前30天以公告的形式通知您，并依照所适用的法律对所持有的您的个人信息进行删除或匿名化处理。
    </p>
    <p style="text-indent: 2em;">
      10、授权同意的例外：
    </p>
    <p style="text-indent: 2em;">
      您充分知晓，根据相关法律法规的要求以下情形中，我们收集、使用您的个人信息无需征得您的授权同意：
    </p>
    <p style="text-indent: 2em;">
      a) 与个人信息控制者履行法律法规规定的义务相关的；
    </p>
    <p style="text-indent: 2em;">
      b) 与国家安全、国防安全直接相关的；
    </p>
    <p style="text-indent: 2em;">
      c) 与公共安全、公共卫生、重大公共利益直接相关的；
    </p>
    <p style="text-indent: 2em;">
      d) 与刑事侦查、起诉、审判和判决执行等直接相关的；
    </p>
    <p style="text-indent: 2em;">
      e)
      出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
    </p>
    <p style="text-indent: 2em;">
      f) 所涉及的个人信息是个人信息主体自行向社会公众公开的；
    </p>
    <p style="text-indent: 2em;">
      g) 根据个人信息主体要求签订和履行合同所必需的；
    </p>
    <p style="text-indent: 2em;">
      h)
      从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
    </p>
    <p style="text-indent: 2em;">
      i) 维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的
      故障；
    </p>
    <p style="text-indent: 2em;">
      j) 个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；
    </p>
    <p style="text-indent: 2em;">
      k) 个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究所必要，
      且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。
    </p>
    <p style="text-indent: 2em;">
      （二）我们如何使用信息
    </p>
    <p style="text-indent: 2em;">
      收集您的个人信息是为了向您提供服务及提升服务质量，为了实现这一目的，我们会把您的信息用于下列用途：
    </p>
    <p style="text-indent: 2em;">
      1.向您提供您使用的 产品或服务，并维护、改进、优化这些服务及服务体验；
    </p>
    <p style="text-indent: 2em;">
      2.为预防、发现、调查欺诈、侵权、危害安全、非法或违反与我们或与我们关联公司的协议、政策或规则的行为，保护您、其他用户或公众以及我们或我们关联公司的合法权益，我们会使用或整合您的个人信息、服务使用信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施；
    </p>
    <p style="text-indent: 2em;">
      3 经您许可的其他用途。
    </p>
    <p style="text-indent: 2em;">
      二、我们如何使用Cookie和同类技术
    </p>
    <p style="text-indent: 2em;">
      为使您获得更轻松的访问体验，您使用
      产品或服务时，我们可能会通过采用各种技术收集和存储您访问
      服务的相关数据，在您访问或再次访问
      服务时,我们能识别您的身份，并通过分析数据为您提供更好更多的服务。包括使用小型数据文件识别您的身份，这么做是为了解您的使用习惯，帮您省去重复输入账户信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie、Flash
      Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
    </p>
    <p style="text-indent: 2em;">
      请您理解，我们的某些服务只能通过使用Cookie才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝
      的Cookie，但拒绝 的Cookie在某些情况下您可能无法使用依赖于cookies的
      服务的部分功能。
    </p>
    <p style="text-indent: 2em;">
      我们不会将Cookies用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookies。您可以清除计算机上保存的所有Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问我们的网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除。
    </p>
    <p style="text-indent: 2em;">
      三、我们如何共享、转让、公开披露您的个人信息
    </p>
    <p style="text-indent: 2em;">
      （一）共享
    </p>
    <p style="text-indent: 2em;">
      我们不会与
      服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：
    </p>
    <p style="text-indent: 2em;">
      1.事先获得您明确的同意或授权；
    </p>
    <p style="text-indent: 2em;">
      2.根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
    </p>
    <p style="text-indent: 2em;">
      3.只有共享您的某些信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
    </p>
    <p style="text-indent: 2em;">
      4.应您合法需求，协助处理您与他人的纠纷或争议；
    </p>
    <p style="text-indent: 2em;">
      5.应您的监护人合法要求而提供您的信息；
    </p>
    <p style="text-indent: 2em;">
      6.根据与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
    </p>
    <p style="text-indent: 2em;">
      7.基于符合法律法规的社会公共利益而使用。
    </p>
    <p style="text-indent: 2em;">
      8.就 中申请保函服务，您同意
      将您的企业信息和单位经办人的个人信息提供给金融机构，否则您无法申请电子保函。您同意使用第三方应用服务时，需阅读并充分了解并遵守第三方服务的产品功能及隐私保护政策。
    </p>
    <p style="text-indent: 2em;">
      9.在您被他人投诉侵犯知识产权或其他合法权利时，有可能在基于顺利解决该投诉的前提下，需要向投诉人披露您的必要资料，以便进行投诉处理的；
    </p>
    <p style="text-indent: 2em;">
      （二）转让
    </p>
    <p style="text-indent: 2em;">
      我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
    </p>
    <p style="text-indent: 2em;">
      1在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。
    </p>
    <p style="text-indent: 2em;">
      2.在
      服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
    </p>
    <p style="text-indent: 2em;">
      (三)公开披露
    </p>
    <p style="text-indent: 2em;">
      我们仅会在以下情况下，公开披露您的个人信息：
    </p>
    <p style="text-indent: 2em;">
      1.获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；
    </p>
    <p style="text-indent: 2em;">
      2.如果我们确定您出现违反法律法规或严重违反 相关协议规则的情况，或为保护
      及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或
      相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及
      已对您采取的措施。
    </p>
    <p style="text-indent: 2em;">
      （四）共享、转让、公开披露个人信息时事先征得授权同意的例外
    </p>
    <p style="text-indent: 2em;">
      以下情形中，我们共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
    </p>
    <p style="text-indent: 2em;">
      1.与个人信息控制者履行法律法规规定的义务相关的；
    </p>
    <p style="text-indent: 2em;">
      2.与国家安全、国防安全直接相关的；
    </p>
    <p style="text-indent: 2em;">
      3.与公共安全、公共卫生、重大公共利益直接相关的；
    </p>
    <p style="text-indent: 2em;">
      4.与刑事侦查犯罪侦查、起诉、审判和判决执行等直接相关的；
    </p>
    <p style="text-indent: 2em;">
      5.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </p>
    <p style="text-indent: 2em;">
      6.您自行向社会公众公开的个人信息；
    </p>
    <p style="text-indent: 2em;">
      7.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
    </p>
    <p style="text-indent: 2em;">
      请您注意，根据法律规定，共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
    </p>
    <p style="text-indent: 2em;">
      四、我们如何保护、保存您的个人信息
    </p>
    <p style="text-indent: 2em;">
      （一）存储地点
    </p>
    <p style="text-indent: 2em;">
      我们在中华人民共和国境内运营
      服务中收集和产生的信息，仅存储在中华人民共和国境内。我们会确保您的个人信息安全得到足够同等的保护。
    </p>
    <p style="text-indent: 2em;">
      （二）存储期限
    </p>
    <p style="text-indent: 2em;">
      在您使用我们的产品与服务期间，您的个人信息将在为了实现本政策所述目的之期限内保存。
    </p>
    <p style="text-indent: 2em;">
      （三）我们将采取以下手段保护您的信息：
    </p>
    <p style="text-indent: 2em;">
      我们会采取各种预防措施来保护您的个人信息，以保障您的个人信息免遭丢失、盗用和误用，以及被擅自取阅、披露、更改或销毁。为确保您个人信息的安全，我们有严格的信息安全规定和流程，并有专门的信息安全团队在公司内部严格执行上述措施。
    </p>
    <p style="text-indent: 2em;">
      我们会采取合理可行的措施，尽力避免收集无关的个人信息，并在限于达成本政策所述目的所需的期限以及所适用法律法规所要求的期限内保留您的个人信息
    </p>
    <p style="text-indent: 2em;">
      在不幸发生个人信息安全事件后，我们将按照法律法规的要求（最迟不迟于30个自然日内）向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
    </p>
    <p style="text-indent: 2em;">
      五、您如何管理个人信息
    </p>
    <p style="text-indent: 2em;">
      我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：
    </p>
    <p style="text-indent: 2em;">
      （一）访问、更正
    </p>
    <p style="text-indent: 2em;">
      我们鼓励您更新和修改您的信息以使其更准确有效。除法律规定外，您有权访问您的信息，并根据对应信息的管理方式自行完成或要求我们进行修改、补充。您可以联系我们的人工客服，申请更正您的信息，我们承诺在7个工作日内完成核查和处理。
    </p>
    <p style="text-indent: 2em;">
      在访问、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障您的账户安全。
    </p>
    <p style="text-indent: 2em;">
      （二）删除
    </p>
    <p style="text-indent: 2em;">
      在以下情形中，您可以通过联系我们的人工客服，向我们提出删除个人信息的请求：
    </p>
    <p style="text-indent: 2em;">
      a）如果我们处理个人信息的行为违反法律法规；
    </p>
    <p style="text-indent: 2em;">
      b）如果我们收集、使用您的个人信息，却未征得您的授权同意；
    </p>
    <p style="text-indent: 2em;">
      c）如果我们处理个人信息的行为违反了与您的约定；
    </p>
    <p style="text-indent: 2em;">
      d）如果您不再使用我们的产品或服务，或您注销了账号；
    </p>
    <p style="text-indent: 2em;">
      e）如果我们终止服务及运营。
    </p>
    <p style="text-indent: 2em;">
      （三）拒绝、返还、转移、索取数据副本
    </p>
    <p style="text-indent: 2em;">
      您可以通过联系我们的人工客服向我们提出拒绝、返还、转移、索取个人信息副本的请求。我们承诺在7个工作日内完成核查和处理。
    </p>
    <p style="text-indent: 2em;">
      （四）改变您授权同意的范围
    </p>
    <p style="text-indent: 2em;">
      每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。
    </p>
    <p style="text-indent: 2em;">
      您可以联系我们的人工客服收回您的授权同意。当您收回同意后，
    </p>
    <p style="text-indent: 2em;">
      我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
    </p>
    <p style="text-indent: 2em;">
      （五）提出异议
    </p>
    <p style="text-indent: 2em;">
      若您认为我们对您个人信息的处理违反了本政策或相关法律法规的要求，您可以联系我们的人工客服或发送邮件方式提出您的异议。您的每一条宝贵建议我们承诺在7个工作日内完成回复和处理。
    </p>
    <p style="text-indent: 2em;">
      （六）注销
    </p>
    <p style="text-indent: 2em;">
      在您需要终止使用我们的服务时，您可以申请注销您的账户，您可以联系我们的人工客服，申请注销您的账户。我们承诺在7个工作日内完成核查和处理。
    </p>
    <p style="text-indent: 2em;">
      我们在此善意地提醒您。注销账户后您的个人信息会保持不可被检索、访问的状态，我
    </p>
    <p style="text-indent: 2em;">
      们将不会再使用或对外提供与该账户相关的个人信息，但您在使用
      使用电子认证服务期间提供或产生的信息我们仍需按照《中华人民共和国电子签名法》的相关要求保存5年以上，且在保存的时间内依法配合有权机关的查询。
    </p>
    <p style="text-indent: 2em;">
      六、未成年人保护
    </p>
    <p style="text-indent: 2em;">
      我们重视未成年人的信息保护，如您为未成年人的，建议您请您的父母或监护人仔细阅读本政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许，父母或监护人明确同意或者保护未成年人所必要的情况下使用，共享，转让或披露此信息。我们将根据国家相关法律法规及本政策的规定保护未成年人的个人信息。
    </p>
    <p style="text-indent: 2em;">
      七、本政策如何更新
    </p>
    <p style="text-indent: 2em;">
      我们的隐私政策可能变更。
    </p>
    <p style="text-indent: 2em;">
      未经您明确同意我们不会限制您按照本隐私政策所应享有的权利。我们会在官方主页（http://tbbh.tiancaibaoxian.com/login）上公告发布对本政策所做的任何变更。
    </p>
    <p style="text-indent: 2em;">
      对于重大变更，我们还会提供更为显著的通知（包括我们会通过网页公示的方式进行意见征集、公告通知甚至向您提供弹窗提示）。
    </p>
    <p style="text-indent: 2em;">
      本政策所指的重大变更包括但不限于：
    </p>
    <p style="text-indent: 2em;">
      1.我们的服务模式发生重大变化。如处理用户信息的目的、用户信息的使用方式等；
    </p>
    <p style="text-indent: 2em;">
      2.我们在控制权、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
    </p>
    <p style="text-indent: 2em;">
      3.用户信息共享、转让或公开披露的主要对象发生变化；
    </p>
    <p style="text-indent: 2em;">
      4.我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </p>
    <p style="text-indent: 2em;">
      5.用户信息安全影响评估报告表明存在高风险时。
    </p>
    <p style="text-indent: 2em;">
      八、如何联系我们
    </p>
    <p style="text-indent: 2em;">
      如果您对
      的个人信息保护政策或数据处理有任何疑问、意见或建议，可以通过以下方式与我们联系，我们邮寄联系方式如下：
    </p>
    <p style="text-indent: 2em;">
      收件人：东莞市科创融资担保有限公司
    </p>
    <p style="text-indent: 2em;">
      地址：东莞市松山湖高新技术开发区新城大道5号道弘龙怡智谷A座8 楼
    </p>
    <p style="text-indent: 2em;">
     电话：0769-22863128
    </p>
    <p style="text-indent: 2em;">
      一般情况下，我们将在收到您相关联系信息并核实您身份后立即或三十天内回复。
    </p>
    <p style="text-indent: 2em;">
     如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向广州市有管辖权的法院提起诉讼来寻求解决方案。
    </p>
    <p style="text-indent: 2em;">
      <!-- 本政策最近更新日期：2021年9月7日 -->
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
